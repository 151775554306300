<template>
  <div class="card">
<div class="input-group">
    <label for="email">Email:</label>
    <input type="email" v-model="email" placeholder="Enter your email">
</div>
<div class="input-group">
    <label for="password">Password:</label>
    <input type="password" v-model="password" placeholder="Enter your password">
</div>
<button class="btn" @click="login">Login</button>
</div>
</template>
<script>
import { mapGetters, mapMutations  } from "vuex";
import NavigationHelper from "@/mixins/navigationHelper";
import axios from 'axios'
export default {
name: 'PublicLayout',
data() {
return {
  email: '',
password: ''
}
},
mixins: [
NavigationHelper,
],
computed:{
...mapGetters("errors", ["getErrors"]),
...mapGetters("auth", [
"getAuthenticationStatus",
"getAccountNotVerified",
"getAccountNotVerifiedEmail",
"getWorkspaceList",
"getAuthenticatedUser",
"getUserType",
"getErrorCode",
"getErrorData",
"getResendVerificationEmail",
]),
...mapGetters("sso",["getAuthenticatedSSOToken", "getIsUserLoggedIn"]),
},
async mounted () {
},
methods: {
...mapMutations("sso", ["setAuthenticatedSSOToken"]),
async login() {
let params ={
  email : this.email,
  password: this.password,
  redirect_url:'',
}
try {
let filesData = await axios.post(`https://ac-api.esigns.io/v1.0/auth/signin/client`, params);
this.$store.dispatch('sso/changeFlag', true);
localStorage.setItem('isLoggedIn', true);
this.setAuthenticatedSSOToken(filesData.data.code);
this.$emit("closeSignInDialog");
} catch (error) {
this.$notify.error({
      title: "Error",
      message: "Please give valid mail Id",
    });
  console.error("Error occurred during login:", error);
}
}
}
};
</script>
<style scoped>
body {
display: flex;
justify-content: center;
align-items: center;
height: 100vh;
margin: 0;
}

.card {
width: 300px;
padding: 20px;
border: 1px solid #ccc;
border-radius: 5px;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.input-group {
margin-bottom: 20px;
}

.input-group label {
display: block;
margin-bottom: 5px;
}

.input-group input {
width: 100%;
padding: 8px;
border-radius: 5px;
border: 1px solid #ccc;
}

.input-group input:focus {
outline: none;
border-color: dodgerblue;
}

.btn {
width: 100%;
padding: 10px;
border: none;
border-radius: 5px;
background-color: dodgerblue;
color: #fff;
cursor: pointer;
}

.btn:hover {
background-color: #0a5cd1;
}
</style>